'use client';
import ReactECharts from 'echarts-for-react';
import styles from './index.module.scss';
import { Button, DatePicker } from 'antd';
import { dashboardDataGraph } from '@/api/dashboard';
import { useEffect, useState } from 'react';
import { JsonObject } from '@/types';
import { useTranslations } from 'next-intl';

const { RangePicker } = DatePicker;

const funDate = (aa: number) => {
  let time2 = '';
  const date1 = new Date();
  const date2 = new Date(date1);
  date2.setDate(date1.getDate() + aa);
  time2 = date2.getFullYear() + '-' + (date2.getMonth() + 1) + '-' + date2.getDate();
  return time2;
};

const Statistic = () => {
  const t = useTranslations();
  const defaultData = {
    legend: {
      data: [t('orderCreatePerDay'), t('paidOrderPerDay'), t('dailyRevenue')],
      x: 'right',
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: [0, 1, 2, 3],
    },
    yAxis: {
      type: 'value',
    },
    tooltip: {
      trigger: 'axis',
    },
    series: [
      {
        name: t('orderCreatePerDay'),
        type: 'line',
        data: [0, 1, 2, 3, 4],
      },
      {
        name: t('paidOrderPerDay'),
        type: 'line',
        data: [0, 1, 2, 3, 4],
      },
      {
        name: t('dailyRevenue'),
        type: 'line',
        data: [0, 1, 2, 3, 4],
      },
    ],
  };
  const [data, setData] = useState<JsonObject>(defaultData);
  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState<[string, string]>([
    funDate(-7),
    new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate(),
  ]);
  const [chartVisible, setChartVisible] = useState(false);

  const getData = async (date: [string, string]) => {
    try {
      setIsLoading(true);
      const res = await dashboardDataGraph({ start_at: date[0], end_at: date[1] });
      const options = {
        ...defaultData,
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: Object.keys(res.data.order_created),
        },
        series: [
          {
            name: t('dashboard.orderCreatePerDay'),
            type: 'line',
            data: Object.values(res.data.order_created),
          },
          {
            name: t('dashboard.paidOrderPerDay'),
            type: 'line',
            data: Object.values(res.data.order_paid),
          },
          {
            name: t('dashboard.dailyRevenue'),
            type: 'line',
            data: Object.values(res.data.order_sum),
          },
        ],
      };
      setData(options);
      setChartVisible(true);
      setIsLoading(false);
    } catch (error) {
      console.log('🚀 ~ getData ~ error:', error);
    }
  };

  useEffect(() => {
    getData(date);
  }, []);

  return (
    <div className={styles['main__row-3']}>
      <div className={styles['main__row__title']}>{t('dashboard.statistic')}</div>
      <div className={styles['main__row-3__rangePicker']}>
        <RangePicker
          format={'YYYY-MM-DD'}
          onChange={(values, formatString) => {
            setDate(formatString);
          }}
          placeholder={[t('global.startDate'), t('global.endDate')]}
        />
        <Button
          type="primary"
          className="ml-10"
          onClick={() => {
            getData(date);
          }}
        >
          {t('global.filter')}
        </Button>
      </div>
      {chartVisible && (
        <ReactECharts
          option={data}
          loadingOption={{ text: `${t('global.loading')}...` }}
          showLoading={isLoading}
          onChartReady={() => getData(date)}
        />
      )}
    </div>
  );
};

export default Statistic;
