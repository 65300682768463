import client from './httpClient';

export const dashboardData = () => client.get('/lecturer/api/v1/dashboard');

type DashboardDataGraphProps = {
  start_at: string;
  end_at: string;
};
export const dashboardDataGraph = (data: DashboardDataGraphProps) =>
  client.get('/lecturer/api/v1/dashboard/graph', data);
