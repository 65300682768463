import(/* webpackMode: "eager" */ "/usr/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/usr/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/usr/app/public/dashboard/demand.png");
;
import(/* webpackMode: "eager" */ "/usr/app/public/dashboard/live.png");
;
import(/* webpackMode: "eager" */ "/usr/app/public/dashboard/tuwen.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/app/src/app/[locale]/(dashboard)/(NoWhiteBackground)/dashboard/DataCardRow.tsx");
;
import(/* webpackMode: "eager" */ "/usr/app/src/app/[locale]/(dashboard)/(NoWhiteBackground)/dashboard/index.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/app/src/app/[locale]/(dashboard)/(NoWhiteBackground)/dashboard/Statistic.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/app/src/components/DispatchHeader/index.tsx");
