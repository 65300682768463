'use client';
import { dashboardData } from '@/api/dashboard';
import useSWR from 'swr';
import styles from './index.module.scss';
import CompareCard from '@/components/Dashboard/CompareCard';
import { useTranslations } from 'use-intl';

const DataCardRow = () => {
  const { data, isLoading } = useSWR('dashboard', dashboardData);
  const t = useTranslations('dashboard');
  return (
    <div className={styles['main__row-1']}>
      {[
        {
          title: t('todayRevenue'),
          key: 'todayRevenue',
          today: data?.data?.income?.today,
          yesterday: data?.data?.income?.yesterday,
          isLoading: isLoading,
        },
        {
          title: t('todayPaidUser'),
          key: 'todayPaidUser',
          today: data?.data?.paid_users_count?.today,
          yesterday: data?.data?.paid_users_count?.yesterday,
          isLoading: isLoading,
        },
        {
          title: t('todayTopicView'),
          key: 'todayTopicView',
          today: data?.data?.topic_reads_count?.today,
          yesterday: data?.data?.topic_reads_count?.yesterday,
          isLoading: isLoading,
        },
        {
          title: t('todayCourseSubscription'),
          key: 'todayCourseSubscription',
          today: data?.data?.subscribers_count?.today,
          yesterday: data?.data?.subscribers_count?.yesterday,
          isLoading: isLoading,
        },
        {
          title: t('todayNewVod'),
          key: 'todayNewVod',
          today: data?.data?.courses_count?.today,
          yesterday: data?.data?.courses_count?.yesterday,
          isLoading: isLoading,
        },
        {
          title: t('todayNewTopic'),
          key: 'todayNewTopic',
          today: data?.data?.topics_count?.today,
          yesterday: data?.data?.topics_count?.yesterday,
          isLoading: isLoading,
        },
      ].map((item) => (
        <CompareCard {...item} key={item.key} />
      ))}
    </div>
  );
};

export default DataCardRow;
